

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("trix")
require("@rails/actiontext")
require("jquery")
require("jquery-mask-plugin")

import Rails from "@rails/ujs";
window.Rails = Rails

import "bootstrap"
//import "data-confirm-modal"
//import "./confirm-modal"
import "./curriculums"
import "./marriage_licenses"
import "./modals"
import "./pages"
import "./people"
import "./../controllers/index"

//$.jMaskGlobals.watchDataMask = true;

$(document).on("turbolinks:load", () => {
 // $('[data-toggle="tooltip"]').tooltip()
 // $('[data-toggle="popover"]').popover()

  $("form").on("keydown", ":input:not(textarea):not(:submit)", function(e) {
    return e.key != "Enter";
  });
})

