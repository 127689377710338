// TODO: Como definir que esse js seja carregado somente pela respectiva view?

document.addEventListener('turbolinks:load', function() {
  deceasedToggle('marriage_license_parent_deceased11', 'parent11-deceased1', 'parent11-deceased2');
  deceasedToggle('marriage_license_parent_deceased12', 'parent12-deceased1', 'parent12-deceased2');
  deceasedToggle('marriage_license_parent_deceased21', 'parent21-deceased1', 'parent21-deceased2');
  deceasedToggle('marriage_license_parent_deceased22', 'parent22-deceased1', 'parent22-deceased2');
  exSpouseToggle();

  function deceasedToggle(check, elem1, elem2) {
    let deceased = document.getElementById(check);

    if(deceased) {
      let deceased1 = document.getElementById(elem1);
      let deceased2 = document.getElementById(elem2);

      deceased1.style['display'] = deceased.checked ? 'none' : 'block';
      deceased2.style['display'] = deceased.checked ? 'block' : 'none';
      deceased.onclick = function() {deceasedToggle(check, elem1, elem2)};
    };
  };

  function exSpouseToggle() {
    let civilStatus1 = document.getElementById('marriage_license_civil_status1');
    let container1 = document.getElementById('marriage_license_container_ex_spouse1');
    let civilStatus2 = document.getElementById('marriage_license_civil_status2');
    let container2 = document.getElementById('marriage_license_container_ex_spouse2');

    if(civilStatus1 && container1) {
      container1.style['display'] = civilStatus1.value == 3 || civilStatus1.value == 4 ? 'block' : 'none';
      civilStatus1.onchange = function() {exSpouseToggle()};
    };

    if(civilStatus2 && container2) {
      container2.style['display'] = civilStatus2.value == 3 || civilStatus2.value == 4 ? 'block' : 'none';
      civilStatus2.onchange = function() {exSpouseToggle()};
    };
  };
});
