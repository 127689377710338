import { rest_client } from "../services/rest_client";
import { Controller } from "@hotwired/stimulus"

const base_url = "https://brasilapi.com.br/api/cep/v2/"

export default class extends Controller {
  static targets = [ "zip_code", "address", "neighborhood", "city", "state", "country" ]

  async fetch_cep() {

    let address = await rest_client(`${base_url}${this.zip_codeTarget.value}`)

    if(address.name == undefined){
      const current_url = `http://${window.location.href.split("/")[2]}/`
      
      address.street != undefined ? this.addressTarget.value = address.street : this.addressTarget.value = "" 
      address.neighborhood != undefined ? this.neighborhoodTarget.value = address.neighborhood : this.neighborhoodTarget.value = ""  
      address.city != undefined ? this.cityTarget.value = address.city : this.cityTarget.value = ""  
      address.state != undefined ? this.stateTarget.value = address.state : this.stateTarget.value = ""  
      if (this.hasCountryTarget) this.countryTarget.value = "BR"
    } else {
      alert("CEP inválido!")
    }
  }
}