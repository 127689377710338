import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Caracteres maiusculos
  upperCase() {
    this.element.value = this.element.value
      .toUpperCase()
  }

  // Caracteres minusculos
  lowerCase() {
    this.element.value = this.element.value
      .toLowerCase()
  }

  // Aceita apenas número
  maskNumber() {
    this.element.value = this.element.value
      .replace(/\D/, "")
  }

  // 000.000.000-00
  maskDocCpf() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }

  // 00.000.000/0000-00
  maskDocCnpj() {
    this.element.value = this.element.value
      .replace(/\D/g,"")
      .replace(/^(\d{2})(\d)/,"$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
      .replace(/\.(\d{3})(\d)/,".$1/$2")
      .replace(/(\d{4})(\d)/,"$1-$2")
  }

  // (00) 00000-0000
  maskPhone() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})(\d)/, "$1-$2")
  }

  // 00000-000
  maskZipCode() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
  }

  // 00/00/0000
  maskDate() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1")
  }

  // R$ 0,00
  maskCurrency() {
    this.element.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.element.value.replace(/\D/g, "")/100).replace('R$','')
    //this.element.value = this.element.value.replace(/\D/g, "")
  }

  // 99.999-6
  maskDocCns() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{1})\d+?$/, "$1")
  }

}
