// TODO: Como definir que esse js seja carregado somente pela respectiva view?

document.addEventListener('turbolinks:load', function() {
  exSpouseToggle();

  function exSpouseToggle() {
    let schooling  = document.getElementById('curriculum_schooling');
    let container1 = document.getElementById('curriculum_container_schooling1');
    let container2 = document.getElementById('curriculum_container_schooling2');

    if(schooling && container1 && container2) {
      container1.style['display'] = schooling.value >= 7 ? 'block' : 'none';
      container2.style['display'] = schooling.value == 7 ? 'block' : 'none';
      schooling.onchange = function() {exSpouseToggle()};
    };
  };
});
