import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import FormatController from "./format_controller.js"
import PaymentController from "./payment_controller.js"
import ZipCodeController from "./zip_code_controller.js"

const application = Application.start()

application.register("format", FormatController)
application.register("zip-code", ZipCodeController)

const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))