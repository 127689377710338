// TODO: Como definir que esse js seja carregado somente pela respectiva view?

document.addEventListener('turbolinks:load', function() {
  personTypeToggle();
  spouseToggle();

  function personTypeToggle() {
    let type1 = document.getElementById('person_person_type_1');
    let type2 = document.getElementById('person_person_type_2');

    if(type1 && type2) {
      let container1 = document.getElementById('person_container_person_type_1');
      let container2 = document.getElementById('person_container_person_type_2');

      container1.style['display'] = type1.checked ? 'block' : 'none';
      container2.style['display'] = type2.checked ? 'block' : 'none';

      type1.onclick = function() {personTypeToggle()};
      type2.onclick = function() {personTypeToggle()};
    };
  };

  function spouseToggle() {
    let civilStatus = document.getElementById('person_civil_status');
    let container = document.getElementById('person_container_spouse');

    if(civilStatus && container) {
      container.style['display'] = civilStatus.value == 2 ? 'block' : 'none';

      civilStatus.onchange = function() {spouseToggle()};
    };
  };
});
