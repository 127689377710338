// TODO: Como definir que esse js seja carregado somente pela respectiva view?

document.addEventListener('turbolinks:load', function() {
  menuPageToggle();

  function menuPageToggle() {
    let menuPage4 = document.getElementById('page_menu_page_4');
    let container = document.getElementById('page_container_file');

    if(menuPage4 && container) {
      container.style['display'] = menuPage4.checked ? 'block' : 'none';

      let menuPage1 = document.getElementById('page_menu_page_1');
      let menuPage2 = document.getElementById('page_menu_page_2');
      let menuPage3 = document.getElementById('page_menu_page_3');

      menuPage1.onchange = function() {menuPageToggle()};
      menuPage2.onchange = function() {menuPageToggle()};
      menuPage3.onchange = function() {menuPageToggle()};
      menuPage4.onchange = function() {menuPageToggle()};
    };
  };
});
