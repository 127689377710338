import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable","delivery_method" ]

  showLinkPayments() {
   // option 
   // if (document.getElementById("birth_certificate_order_status_1").checked ||
   //     document.getElementById("birth_certificate_order_status_3").checked ) {
   if (document.getElementById("birth_certificate_order_status").value == '1' ||
       document.getElementById("birth_certificate_order_status").value == '4' ) {

   this.hideTargets() }
    else {
        this.showTargets()
    }
  }

  showAddress() {
    if (this.delivery_methodTarget.value == '0') {
        document.getElementById('data-address').hidden = true
        }
    else {
      document.getElementById('data-address').hidden = false
    }
  }

  showTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = false
    });
  }

  hideTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = true
    });
  }



}
